import type { ISbStoriesParams, ISbStoryData, StoryblokBridgeConfigV2 } from '@storyblok/vue';
import { useState } from 'nuxt/app';
import { useI18n } from 'vue-i18n';
import resolve_relations from '@/storyblok/resolve_relations';
import useAsyncCommon from '@/utils/storyblok/useAsyncCommon';
import { getStory } from '@/api';

/**
 * Modified version of useAsyncStoryblok that injects the current locale
 * into the requested resource and updates the data if the locale changes.
 */
export default async function useAsyncStoryblokWithI18n<T extends ISbStoryData = ISbStoryData>(
  path: string,
  apiOptions: ISbStoriesParams & { find_by?: string } = {},
  bridgeOptions: StoryblokBridgeConfigV2 = {}
) {
  const uniqueKey = `${JSON.stringify(apiOptions)}${path}`;
  const story = useState<T>(`${uniqueKey}-state`);

  const i18n = useI18n();

  return useAsyncCommon(
    // @ts-ignore
    story,
    async () =>
      getStory(path, {
        ...apiOptions,
        language: i18n.locale.value,
        resolve_relations,
      }),
    bridgeOptions
  );
}
